import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import AppModal from "../Modal/Modal";

import { EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";

import "./IntroSection.scss";
import HomePageService from "../../services/IntroService";
import LanguageContext from '../../context/LanguageContext';
import RequestService from "../../services/RequestService";

function IntroSection(props) {
    // States
    const [data, setData] = useState([])
    const [open, setOpen] = useState(props.callback || false)
    const [success, setSuccess] = useState(false)
    const [notSend, setNotSend] = useState(false)
    const [language] = useContext(LanguageContext)
    const { t } = useTranslation()
    const { loading, error, sendRequest } = RequestService()
    const { getIntro, } = HomePageService()

    const toggleOpen = () => setOpen(!open)
    const toggleSuccess = () => setSuccess(!success)
    const toggleNotSend = () => setNotSend(!notSend)

    // Submit form
    const submitForm = (values) => {
        const body = JSON.stringify({
            name: values.name,
            phone_number: values.number,
            message: values.description
        })

        if (values.number.length === 19) {
            try {
                sendRequest(body)
                if (!error) {
                    toggleOpen()
                    toggleSuccess()
                }
            } catch {
                toggleOpen()
                toggleNotSend()
            }
        }
    }

    // Effects
    useEffect(() => {
        getIntro().then(res => {
            setData(res)
        })
    }, [language])

    return (
        <section className="intro">
            {data ?
                (<Container>
                    <Row className="flex-md-row flex-column-reverse">
                        <Col data-aos="zoom-out" md={6} className="d-flex flex-column justify-content-center">
                            <h1 className="intro__title">
                                {data.title}
                            </h1>
                            <p className="intro__text">
                                {data.description}
                            </p>
                            <button onClick={toggleOpen} className="button button_orange">{t("submit")}</button>
                        </Col>
                        <Col md={6} data-aos="zoom-out" className="position-relative d-flex justify-content-center">
                            <div className="intro__circle" />
                            <Swiper
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false
                                }}
                                loop={true}
                                speed={2000}
                                spaceBetween={30}
                                effect={"fade"}
                                modules={[EffectFade, Autoplay]}
                                className="mySwiper"
                                allowTouchMove={false}
                                disableOnInteraction={false}
                            >
                                <SwiperSlide>
                                    <div className="intro__card">
                                        {t("animation1")} 712-811
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="intro__card">
                                        {t("animation2")}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="intro__card">
                                        {t("animation3")}
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </Col>
                    </Row>
                </Container>) : null
            }
            <AppModal
                type="application"
                handleCloseModal={toggleOpen}
                submitForm={submitForm}
                open={open}
                loading={loading}
            />
            <AppModal
                type="successModal"
                handleCloseModal={toggleSuccess}
                open={success}
            />
            <AppModal
                type="errorModal"
                handleCloseModal={toggleNotSend}
                open={notSend}
            />
        </section>
    )
}

export default IntroSection;