import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ContactPageService from '../../services/ContactService';
import LanguageContext from '../../context/LanguageContext';
import { Link } from "react-router-dom";

import { Col, Container, Row } from 'react-bootstrap'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonIcon from "@mui/icons-material/Person";

import './ContactsPage.scss';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';

function ContactsPage() {
    // States
    const [data, setData] = useState(null)
    const [language] = useContext(LanguageContext)
    const { getContact } = ContactPageService()
    const { t } = useTranslation()

    // Unblock map
    const unBlockMap = (e) => {
        e.target.addEventListener("click", () => {
            e.target.classList.add("show")
        })
    }
    const blockMap = () => {
        document.querySelector(".contacts__map-block").classList.remove("show")
    }

    // Effects
    useEffect(() => {
        getContact().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <section className='contacts mainTop py'>
            {data ?
                (
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <BreadCrumb breadcrumb={t("menuLink6")} />
                                <h2 className='contacts__title d-flex justify-content-between flex-wrap'>
                                    <span className="d-block mt-3">
                                        {t("menuLink6")}
                                    </span>
                                    <Link to="/callback" className="menu__area button button_orange text-center mt-3">
                                        {t("callback")}
                                    </Link>
                                </h2>
                                <p className='contacts__description'>
                                    {t("contactsTitle")}
                                </p>

                                {data.phone_numbers ?
                                    (<><h3 className='contacts__subtitle'>{t("phone")}</h3>
                                        {data.phone_numbers.map((item, i) => {
                                            return (
                                                <h4 className='contacts__about' key={i}>
                                                    <LocalPhoneOutlinedIcon />
                                                    {item}
                                                </h4>
                                            )
                                        })}
                                    </>) : null}

                                {data.work_hours ?
                                    (<>
                                        <h3 className='contacts__subtitle'>{t("workTime")}</h3>
                                        <h4 className='contacts__about'>
                                            <DateRangeOutlinedIcon />
                                            {data.work_hours}
                                        </h4>
                                    </>) : null}

                                {data.location ?
                                    (<> <h3 className='contacts__subtitle'>{t("adress")}</h3>
                                        <h4 className='contacts__about'>
                                            <LocationOnOutlinedIcon />
                                            {data.location.name}
                                        </h4>
                                        <div
                                            className='contacts__map'
                                            onMouseEnter={unBlockMap}
                                            onMouseLeave={blockMap}
                                        >
                                            <div
                                                className="contacts__map-block"
                                            />
                                            <div className="map" dangerouslySetInnerHTML={{ __html: data.location.location }} />
                                        </div>
                                    </>) : null}
                            </Col>
                        </Row>
                    </Container>
                ) : null}
        </section>
    )
}

export default ContactsPage