import React, { useContext, useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import illustration from '../../resources/img/Accardion/illustration.png';

import LanguageContext from '../../context/LanguageContext';
import HomePageService from '../../services/IntroService';
import './AdvantageSection.scss'

function AdvantageSection() {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel  : false);
    };

    const [data, setData] = useState([])
    const { loading, error, getBenefits, } = HomePageService()
    const [language] = useContext(LanguageContext)
    useEffect(() => {
        getBenefits().then(res => {
            setData(res)
        })
    }, [language])


    return (
        <section className='advantage py'>
            {data ?
                (<Container>
                    <h2 className='advantage__title'>{t("advantageTitle")}</h2>
                    <Row className='flex-lg-row flex-column'>
                        <Col data-aos='fade-right'>
                            {data.map(i => {
                                return (
                                    <Accordion key={i.id} expanded={expanded === i.id} onChange={handleChange(i.id)}>
                                        <AccordionSummary
                                        >
                                            <div className={expanded === i.id ? "advantage__icon advantage__orange" : "advantage__icon"  }>
                                                <img src={i.icon} alt={i.title} />
                                            </div>
                                            <h3 className='advantage__name'>
                                                {i.title}
                                            </h3>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                {i.description}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </Col>
                        <Col data-aos='fade-left' className='d-lg-flex align-items-center justify-content-center d-none d-md-block '>
                            <img className='advantage__img' src={illustration} alt="?" />
                        </Col>
                    </Row>
                </Container>) : null}
        </section>
    )
}

export default AdvantageSection;