import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import HomePageService from '../../services/IntroService';
import LanguageContext from '../../context/LanguageContext';
import './QuestionsSection.scss'

function QuestionsSection() {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [data, setData] = useState(null)
    const { loading, error, getFaq, } = HomePageService()
    const [language] = useContext(LanguageContext)
    useEffect(() => {
        getFaq().then(res => {
            setData(res)
        })
    }, [language])

    return (
        <section className='questions py'>
            {data ?
                (<Container>
                    <h2 className='questions__title'>
                        {t('questionsTitle')}
                    </h2>
                    <Row className='justify-content-center'>
                        <Col md={10}>
                            {data.map(i => {
                                return (
                                    <div key={i.id} data-aos="zoom-in-up">
                                        <Accordion className='mb-3'  expanded={expanded === i.id} onChange={handleChange(i.id)}>
                                            <AccordionSummary
                                                expandIcon={expanded === i.id ? <RemoveIcon /> : <AddIcon />}
                                            >
                                                <Typography className='questions__name'>
                                                    {i.question}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className='questions__answer'>
                                                    {i.answer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>) : null}
        </section>
    )
}

export default QuestionsSection;