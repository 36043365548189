import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const ServicesService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getService = async (id) => {
        const data = await request(`api/landing/services/${id}`)

        return data;
    }

    return { loading, error, getService }
}

export default ServicesService;