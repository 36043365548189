import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const HeaderPageService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getHeader = async () => {
        const data = await request("api/landing/header")

        return data;
    }
    return { loading, error, getHeader }
}

export default HeaderPageService;