import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const TariffPageService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getTariff = async () => {
        const data = await request("api/landing/plans")

        return data;
    }
    return { loading, error, getTariff }
}

export default TariffPageService;