import React, { useContext, useEffect, useState } from 'react';
import HomePageService from '../../services/IntroService';
import LanguageContext from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from 'react-bootstrap';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

import "swiper/css/effect-coverflow";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css';
import "./SliderSection.scss";

function SliderSection() {
    // States
    const [data, setData] = useState([])
    const [language] = useContext(LanguageContext)
    const { t } = useTranslation()
    const { getFacilities } = HomePageService()

    // Effects
    useEffect(() => {
        getFacilities().then(res => {
            setData(res)
        })
    }, [language])

    return (
        <section className='slider py'>
            {data ?
                (<Container>
                    <h2 className='slider__title'>{t("sliderTitle")}</h2>
                    <div className='position-relative d-flex align-items-center'>
                        <div className='swiper-prev1'> </div>
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView="auto"
                            spaceBetween={10}
                            loopedSlides="3"
                            initialSlide="1"
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false
                            }}
                            navigation={{
                                nextEl: '.swiper-next1',
                                prevEl: '.swiper-prev1',
                            }}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 0,
                                modifier: 1,
                                slideShadows: false,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                            }}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                            className="mySwiper pb-4"
                        >
                            {data.map(item => {
                                return (
                                    <SwiperSlide key={item.id}>
                                        <div className='slider__card'>
                                            <img className='slider__card__icon' width="77" src={item.icon} alt={item.title} />
                                            <p className='slider__card__text'>
                                                {item.title}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        <div className='swiper-next1' />
                    </div>
                </Container>) : null}
        </section>
    )
}

export default SliderSection