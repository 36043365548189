import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LanguageContext from '../../context/LanguageContext'
import HomePageService from '../../services/IntroService'
import './RateSection.scss'

function RateSection() {
    const { t } = useTranslation()
    return (
        <section className='rate py'>
            <Container>
                <h2 className='rate__title'>
                    {t("rateTitle")}
                </h2>
                <Col className='m-auto' md={8}>
                    <RateTariffs />
                </Col>
                <p className='rate__bottom'>
                    {t("rateText")}
                </p>
            </Container>
        </section>
    )
}

export const RateTariffs = () => {
    const { t } = useTranslation()

    const [data, setData] = useState(null)
    const { loading, error, getPlans } = HomePageService()
    const [language] = useContext(LanguageContext)
    useEffect(() => {
        getPlans().then(res => {
            setData(res)
        })
    }, [language]);


    return (
        <Row className='justify-content-center'>
            {data ?
                (<div>
                    {data.data.map(i => {
                        return (
                            <div key={i.id} className='rate__line'>
                                <div className='d-flex align-items-center'>
                                    <img src={i.icon} alt="?" width="24" height="24" />
                                    <span>{i.title}</span>
                                </div>
                                <h4>
                                    {i.price} {t("rateSms")}
                                </h4>
                            </div>
                        )
                    })}
                </div>
                ) : null}
        </Row>
    )
}

export default RateSection