import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ServicesService from "../../services/ServicesService";

import { Col, Container, Row } from 'react-bootstrap';
import "./SmsList.scss";
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';
import LanguageContext from '../../context/LanguageContext';

function SmsList() {
    // States
    const [data, setData] = useState()
    const { getService } = ServicesService()
    const [language] = useContext(LanguageContext)
    const { id } = useParams()

    // Get & Set service
    const updateData = () => {
        getService(id).then(res => {
            setData(res)
        })
    }

    // Effects
    useEffect(() => {
        updateData()
    }, [id, language])

    return (
        <section className='smslist mainTop py'>
            {
                data ? (
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <BreadCrumb breadcrumb={data.title}/>
                                <h2 className='smslist__title'>
                                    {data.title}
                                </h2>
                                <div>
                                    <img src={data.banner} alt={data.title} />
                                    <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : null
            }
        </section>
    )
}

export default SmsList