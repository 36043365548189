import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const HomePageService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getIntro = async () => {
        const { intro } = await request("api/landing")

        return intro;
    }

    const getAbout = async () => {
        const { about } = await request("api/landing")

        return about;
    }

    const getBenefits = async () => {
        const { benefits } = await request("api/landing")

        return benefits
    }

    const getFacilities = async () => {
        const { facilities } = await request("api/landing")

        return facilities
    }

    const getPlans = async () => {
        const { plans } = await request("api/landing")

        return plans
    }

    const getFaq = async () => {
        const { faq } = await request("api/landing")

        return faq
    }

    return { loading, error, getIntro, getAbout, getPlans, getBenefits, getFacilities, getFaq }
}

export default HomePageService;