import { useCallback, useState } from 'react'
import useDomain from './useDomain'

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { _url } = useDomain()

    const getLang = () => {
        return localStorage.i18nextLng
    }

    const request = useCallback(async (url, method = 'GET', body = null, headers = {
        'Content-Type': 'application/json',
        "Lang": getLang() || "ru",
        "Authorization": "Basic YXBpOmFwaQ==",
        "Access-Control-Allow-Origin": "*"
    }) => {

        setLoading(true)

        try {
            const response = await fetch(`${_url}${url}`, { method, body, headers })
            if (!response.ok) {
                throw new Error(`Couldn't fetch ${url}, status: ${response.status}`)
            }
            const data = await response.json()

            setLoading(false)
            return data

        } catch (error) {
            console.log(error.message)
            setLoading(false)
            setError(true)
            throw error
        }
    }, [])

    const clearError = () => setError(false)

    return { loading, error, request, clearError }
}