import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const FooterPageService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getFooter = async () => {
        const data = await request("api/landing/footer")

        return data;
    }
    return { loading, error, getFooter }
}

export default FooterPageService;