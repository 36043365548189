import {useEffect, useState} from 'react';
import * as Yup from "yup";
import {useField} from "formik";
import {useTranslation} from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal/';
import {
    Backdrop,
    Box,
    Fade,
    // FormControl,
    // IconButton,
    // InputAdornment,
    // InputLabel,
    // OutlinedInput,
} from "@mui/material";
import {Form, Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import {TextField} from "@mui/material";
import InputMask from "react-input-mask";

import './Modal.scss';

export const MyTextInput = ({label, ...props}) => {
    const [field, meta] = useField(props)

    return (
        <>
            <TextField
                {...props}
                {...field}
                label={label}
                variant="outlined"
                error={meta.touched && meta.error}
                fullWidth
            />
            {meta.touched && meta.error ? (
                <i className="error">{meta.error}</i>
            ) : null}
        </>
    )
}

export default function AppModal(props) {
    // States
    const [open, setOpen] = useState(props.open)
    const {t} = useTranslation()

    // Modal styles
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxWidth: "calc(100% - 30px)",
        maxHeight: "90vh",
        overflow: "auto",
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 3,
    };

    // Effects
    useEffect(() => {
        setOpen(props.open)
    }, [props])

    const renderModalContent = () => {
        switch (props.type) {
            case "application":
                return (
                    <Formik
                        initialValues={{
                            name: "",
                            number: "",
                            description: "",
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string()
                                .min(2, t("modalNameMin"))
                                .required(t("modalRequired")),
                            number: Yup.string()
                                .min(19)
                                .required(t("modalRequired")),
                        })}
                        onSubmit={props.submitForm}
                    >
                        {({values, resetForm, errors, handleChange, handleBlur}) => (
                            <Form>
                                <Row>
                                    <h3>{t("submit")}</h3>
                                    <Col xs={12} className="mb-4">
                                        <h6>{t("modalNameLabel")}</h6>
                                        <MyTextInput
                                            label={t("modalName")}
                                            name="name"
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} className="mb-4">
                                        <h6>{t("modalNumberLabel")}</h6>
                                        <InputMask
                                            mask="+\9\9\8 \(99) 999-99-99"
                                            maskChar={null}
                                            value={values.number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="number"
                                            error={errors.number}
                                            fullWidth
                                            required
                                        >
                                            {inputProps => (
                                                <TextField
                                                    label={t("modalNumber")}
                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                        {
                                            errors.number ? (
                                                <i className="error">{t("modalRequired")}</i>
                                            ) : null
                                        }
                                    </Col>
                                    <Col xs={12} className="mb-4">
                                        <h6>{t("modalTextLabel")}</h6>
                                        <MyTextInput
                                            label={t("modalText")}
                                            name="description"
                                            rows={5}
                                            multiline
                                        />
                                    </Col>
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type="reset"
                                            onClick={() => {
                                                resetForm()
                                                props.handleCloseModal()
                                            }}
                                            className='button button_cancel'
                                            disabled={props.loading}
                                        >
                                            {t("cancel")}
                                        </button>
                                        <button
                                            type="submit"
                                            className='button button_enter'
                                            disabled={props.loading}
                                        >
                                            {t("send")}
                                        </button>
                                    </div>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                )

            case "successModal":
                return (
                    <div className='success__modal'>
                        <div className='success__modal-inner'>
                            <div className='success__check'>
                                <CheckIcon/>
                            </div>
                            <h3 className='success__text'>
                                {t("successTitle")}
                            </h3>
                        </div>
                        <button
                            type="reset"
                            onClick={() => {
                                props.handleCloseModal()
                            }}
                            className='button button_cancel'>
                            {t("close")}
                        </button>
                    </div>
                )

            case "errorModal":
                return (
                    <div className='success__modal'>
                        <div className='success__modal-inner'>
                            <div className='reject__check'>
                                <CloseIcon/>
                            </div>
                            <h3 className='success__text'>
                                {t("errorTitle")}
                            </h3>
                        </div>
                        <button
                            type="reset"
                            onClick={() => {
                                props.handleCloseModal()
                            }}
                            className='button button_cancel'>
                            {t("close")}
                        </button>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 300,
            }}
        >
            <Fade in={open} className="application">
                <Box sx={style}>
                    {renderModalContent()}
                </Box>
            </Fade>
        </Modal>
    )
}