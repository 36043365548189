import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import empty from '../../resources/img/empty.png';
import { useTranslation } from 'react-i18next';
import DocumentPageService from '../../services/DocumentService';
import { Link } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import xlsx from "../../resources/img/icons/excel.svg"
import docx from "../../resources/img/icons/word.svg"
import pdf from "../../resources/img/icons/pdf.svg"
import docFile from "../../resources/img/icons/doc.svg"

import './DocumentPage.scss';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';

const DocumentPage = () => {
    // State 
    const [data, setData] = useState([])
    const { loading, error, getDocument } = DocumentPageService()
    const [language] = useContext(LanguageContext)
    const { t } = useTranslation()

    // Get document icon
    const getFileIcon = (fileName, alt) => {
        if (fileName.includes(".xlsx")) {
            return <img src={xlsx} className='document__icon' alt={alt} />
        } else if (fileName.includes(".docx") || fileName.includes(".doc")) {
            return <img src={docx} className='document__icon' alt={alt} />
        } else if (fileName.includes(".pdf")) {
            return <img src={pdf} className='document__icon' alt={alt} />
        } else {
            return <img src={docFile} className='document__icon' alt={alt} />
        }
    }

    getFileIcon("http://185.92.148.127:3479/uploads/document/1647793672935410138daad74a92066efb5efb2e5eb43faaf958bcc848a02.xlsx")

    // Effect
    useEffect(() => {
        getDocument().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <section className='document mainTop py'>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>

                        {
                            data.documents && data.documents.length > 0 ? <>
                                <BreadCrumb breadcrumb={t("menuLink5")} />
                                <h2 className='document__title'>
                                    {t("menuLink5")}
                                </h2>
                                {data.documents.map(i => {
                                    return (
                                        <div key={i.id} className='document__card'>
                                            {getFileIcon(i.file, i.title)}
                                            <h5 className='document__cardtitle'>{i.title} • {i.size}</h5>
                                            <div className='d-md-flex align-items-end'>
                                                <div
                                                    className='document__cardtext pe-md-5'
                                                    dangerouslySetInnerHTML={{ __html: i.description }}>
                                                </div>
                                                <a
                                                    href={i.file}
                                                    download
                                                    className='button button_blue button_download ms-auto text-white'>
                                                    <DownloadOutlinedIcon />{t("download")}
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* Description */}
                                <div dangerouslySetInnerHTML={{ __html: data.description }} className="mt-5 pt-4 border-top" />
                            </> : <>
                                {
                                    !loading ? (
                                        <Row className="align-items-center flex-md-row flex-column-reverse ">
                                            <Col md={6}>
                                                <h1>{t("emptytitle")}</h1>
                                                <p>{t("emptytext")}</p>
                                                <button className='button button_orange w-auto'>
                                                    <Link to="/">
                                                        {t("sendbtn")}
                                                    </Link>
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <img className='document__image' src={empty} alt="empty" />
                                            </Col>
                                        </Row>
                                    ) : null
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default DocumentPage;