import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LanguageContext from '../../context/LanguageContext'
import TariffPageService from '../../services/TariffService'
import { BreadCrumb } from '../BreadCrumb/BreadCrumb'
import { RateTariffs } from '../Sections/RateSection'
import "./Tariffs.scss"

function Tariffs() {
    const { t } = useTranslation()

    const [data, setData] = useState([])
    const { loading, error, getTariff } = TariffPageService()
    const [language] = useContext(LanguageContext)
    useEffect(() => {
        getTariff().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <section className='tariffs mainTop py'>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <BreadCrumb breadcrumb={t('menuLink4')} />
                        <h2 className='tariffs__title'>
                            {t('menuLink4')}
                        </h2>
                        <RateTariffs />
                        <div className='tariffs__text' dangerouslySetInnerHTML={{ __html: data.description }} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Tariffs