import { useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';
import ScrollTop from "../ScrollTop/ScrollTop";
import ScrollToTop from 'react-scroll-to-top';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import MainPage from '../Pages';
import AboutPage from '../Pages/About';
import SmsList from '../Pages/SmsList';
import Tariffs from '../Pages/Tariffs';
import DocumentPage from '../Pages/DocumentPage';
import ContactsPage from '../Pages/ContactsPage';
import Page404 from '../404/404';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import '../../styles/_main.scss';
import '../../styles/Buttons.scss';
import '../../styles/Forms.scss';
import '../../styles/Various.scss';

function App() {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'ru')

    return (
        <Router>
            {/*<Suspense fallback={"Loading..."}>*/}
            <LanguageContext.Provider value={[language, setLanguage]}>
                <Header />
                <main>
                    <ScrollTop>
                        <Routes>
                            <Route exact path="/" element={<MainPage />} />
                            <Route exact path="/callback" element={<MainPage callback={true} />} />
                            <Route exact path="/about-service" element={<AboutPage />} />
                            <Route exact path="/services" element={<Navigate to="/" />} />
                            <Route exact path="/services/:id/" element={<Navigate to="/" />} />
                            <Route exact path="/services/:id/:slug" element={<SmsList />} />
                            <Route exact path="/tariffs" element={<Tariffs />} />
                            <Route exact path="/documents" element={<DocumentPage />} />
                            <Route exact path="/contacts" element={<ContactsPage />} />
                            <Route path="*" element={<Page404 />} />
                        </Routes>
                    </ScrollTop>
                </main>
                <ScrollToTop smooth top="400" color="#291258" />
                <Footer />
            </LanguageContext.Provider>
            {/*</Suspense>*/}
        </Router>
    );
}

export default App