import AOS from "aos";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HomePageService from "../../services/IntroService";
import LanguageContext from "../../context/LanguageContext";

import 'aos/dist/aos.css';
import "./AboutSection.scss";

function AboutSection() {
    const { t } = useTranslation()
    const [data, setData] = useState(null)
    const { loading, error, getAbout } = HomePageService()
    const [language] = useContext(LanguageContext)
    useEffect(() => {
        getAbout().then(res => {
            setData(res)
        })
    }, [language]);

    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, []);


    return (
        <section className="about py">
            {data ? (
                <Container>
                    <Row>
                        <Col data-aos="fade-up" lg="6" className="pe-lg-5">
                            <img
                                className="about__work"
                                src={data.banner}
                                width="600"
                                height="100%"
                                alt="?" />
                        </Col>
                        <Col data-aos="fade-up" lg="6 d-flex flex-column justify-content-center ps-lg-5">
                            <h5 className="about__company pt-lg-0 pt-4">
                                {t("aboutCompany")}
                            </h5>
                            <h2 className="about__title">
                                {data.title}
                            </h2>
                            <p className="about__text">
                                {data.description}
                            </p>
                            <Row className="text-lg-start text-center">
                                {data.counters.map((i, index) => {
                                    return (
                                        <Col key={index}>
                                            <h3 className="about__count">
                                                {i.count}
                                            </h3>
                                            <h6 className="about__years">
                                                {i.title}
                                            </h6>
                                        </Col>
                                    )
                                }
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            ) : null}
        </section>
    )
}

export default AboutSection;