import { Col, Container, Row } from "react-bootstrap";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useContext, useEffect, useState } from "react";
import FooterPageService from "../../services/FooterService";
import LanguageContext from "../../context/LanguageContext";

import './Footer.scss'

const Footer = () => {
    return (
        <View />
    )
}

const View = props => {
    // States
    const [data, setData] = useState(null)
    const [language] = useContext(LanguageContext)
    const { loading, error, getFooter, } = FooterPageService()

    // Effects
    useEffect(() => {
        getFooter().then(res => {
            setData(res)
        })
    }, [language])

    return (
        <footer className="footer">
            {data ?
                <Container>
                    <Row className="justify-content-between text-md-start text-center">
                        <Col lg={3} md={4}>
                            <h4 className="footer__title">smska.uz</h4>
                            <h6 className="footer__subtitle">OOO «IPLUS»</h6>
                        </Col>
                        <Col lg={3} md={4}>
                            {data.email ?
                                <a href={`mailto:${data.email}`} className="d-flex justify-content-center">
                                    <EmailOutlinedIcon />
                                    <h5 className="footer__info">{data.email}</h5>
                                </a> : null}
                            {
                                data.phone_numbers ?
                                data.phone_numbers.map((item,i) => {
                                    return (
                                        <a
                                            href={`tel:${item}`}
                                            className="d-flex justify-content-center"
                                            key={i}
                                        >
                                            <LocalPhoneOutlinedIcon />
                                            <h5 className="footer__info">{item}</h5>
                                        </a>
                                    )
                                }) : null
                            }
                        </Col>
                        <Col lg={3} md={4}>
                            {data.location ?
                                <div className="d-flex justify-content-md-start justify-content-center">
                                    <LocationOnOutlinedIcon />
                                    <h6 className="footer__subtitle">
                                        {data.location}
                                    </h6>
                                </div> : null}
                        </Col>
                        <Col xs={12}>
                            <div className="footer__social my-md-5 my-4 d-flex justify-content-center">
                                {data.instagram ?
                                    <a href={data.instagram}>
                                        <InstagramIcon className="mr-4" />
                                    </a> : null
                                }
                                {data.facebook ?
                                    <a href={data.facebook}>
                                        <FacebookIcon className="mr-4" />
                                    </a> : null
                                }
                                {data.twitter ?
                                    <a href={data.twitter}>
                                        <TwitterIcon className="mr-4" />
                                    </a> : null
                                }
                                {data.telegram ?
                                    <a href={data.telegram}>
                                        <TelegramIcon />
                                    </a> : null
                                }
                            </div>
                            <h6 className="footer__subtitle text-center mt-5 mb-0">
                                © 2022. OOO «IPLUS»
                            </h6>
                        </Col>
                    </Row>
                </Container> : null}
        </footer>
    )
}

export default Footer