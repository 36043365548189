import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntoSection";
import QuestionsSection from "../Sections/QuestionsSection";
import RateSection from "../Sections/RateSection";
import SliderSection from "../Sections/SliderSection";

export default function MainPage(props) {
    return (
        <>
            <IntroSection callback={props.callback}/>
            <AboutSection />
            <SliderSection />
            <AdvantageSection />
            <RateSection />
            <QuestionsSection />
        </>
    )
}