import React, {useContext, useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import LanguageContext from '../../context/LanguageContext'
import AboutPageService from '../../services/AboutService'
import { BreadCrumb } from '../BreadCrumb/BreadCrumb'
import './About.scss'

export default function AboutPage() {
    const [t] = useTranslation()
    const [data, setData] = useState(null)
    const {loading, error, getAbout} = AboutPageService()
    const [language] = useContext(LanguageContext)

    useEffect(() => {
        getAbout().then(res => {
            setData(res)
        })
    }, [language]);


    return (
        <section className='aboutpage mainTop py'>
            {data ?
                (
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8}>
                            <BreadCrumb breadcrumb={t("menuLink2")}/>
                                <h2 className='aboutpage__title'>
                                    {t("menuLink2")}
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: data.description}}></div>
                            </Col>
                        </Row>
                    </Container>
                ) : null}
        </section>
    )
}
