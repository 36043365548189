import { useHttp } from "../hooks/http.hook"
import useDomain from "../hooks/useDomain"

const AboutPageService = () => {
    const { _url } = useDomain()
    const { loading, error, request } = useHttp()

    const getAbout = async () => {
        const data = await request("api/landing/about")

        return data;
    }

    return { loading, error, getAbout }
}

export default AboutPageService;