import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './BreadCrumb.css'

export function BreadCrumb(props) {
    const { t } = useTranslation()
    return (
        <div className='breadcrumb'>
            <Link className='me-2' to="/">{t("main")} </Link> / <span className='ms-2'>  {props.breadcrumb}</span>
        </div>
    )
}
