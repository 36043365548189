import { useTranslation } from 'react-i18next'

function useMenu() {
    const { t } = useTranslation()

    const getLi = [
        {
            link: '/about-service',
            title: t("menuLink2")
        },
        {
            link: '/services',
            title: t("menuLink3"),
            children: [
                {
                    link: '/services/smslist',
                    title: t("menuLink3_1")
                }
            ]
        },
        {
            link: '/tariffs',
            title: t("menuLink4")
        },
        {
            link: '/documents',
            title: t("menuLink5")
        },
        {
            link: '/contacts',
            title: t("menuLink6")
        },
    ]

    const getMobLi = [
        {
            link: '/about-service',
            title: t("menuLink2")
        },
        {
            link: '/tariffs',
            title: t("menuLink4")
        },
        {
            link: '/documents',
            title: t("menuLink5")
        },
        {
            link: '/contacts',
            title: t("menuLink6")
        },
    ]

    return { getLi, getMobLi }
}

export default useMenu;